import * as actionTypes from "../../actions/Home/action-types";

export const initialState = {
    focusWebsiteField: false
}

const updateState = (name, state, payload) => {
    return Object.assign({}, state, {
        [name]: payload
    })
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_FOCUS_WEBSITE_FIELD:
            return updateState("focusWebsiteField", state, action.payload);
        default:
            return state;
    }
}