import * as actionTypes from '../../actions/Account/action-types';
import {subDays} from "date-fns";

const defaultStartDate = subDays(new Date(), 7);
const defaultEndDate = new Date();


export const initialState = {
  isCampaignManagerGuestLoader: true,
  aiFormAnswersLoading: false,
  loadSampledProspect: false,
  isAiFormAnswers: false,
  hideAiBot: true,
  aiAnswersLoading: {
    titles: true,
    company_size: true,
    focused_titles: true,
  },
  aiAnswersError: {
    titles: '',
    company_size: '',
    focused_titles: '',
  },
  isFormCompleted: false,
  toggleEditLoader: false,
  attachmentCampaignId: null,
  isNewUser: false,
  isCampaignEditable: false,
  isCampaignManagerGuest: true,
  isEditModeEnabled: false,
  isEditCampaignEdited: true,
  editedSteps: {
    audience: null,
    creative: null,
    delivery_method: null,
    budget: null,
    exception_audience: null
  },
  draft_campaign_budget: null,
  draft_campaign_budget_type: null,
  draft_campaign_success: null,
  draft_campaign_price_point: "",
  draft_campaign_hear_about_us: "",
  draft_campaign_cost_per_lead: "",
  draft_campaign_sales_cycle_length: "",
  draft_campaign_id: null,
  campaign_id: null,
  incompleteSteps: {
    audience: "",
    delivery_method: "",
    creative: ""
  },
  incompleteFields: {
    audience: {},
    delivery_method: {},
    creative: {},
    budget: {}
  },
  campaignPhaseId: null,
  campaignStatus: "",
  targetAudience: null,
  sampledProspects: [],
  forecastedReach: {
    targetAudienceSize: 0,
    reach: {
      lower_bound: 100,
      upper_bound: 250
    },
    estimated_leads: {
      lower_bound: 2,
      upper_bound: 4
    }
  },
  audience_attachments: [],
  creative_attachments: [],
  campaignLoader: true,
  campaignData: {
    campaign_name: '',
    company_name: '',
    campaign_objective: '',
    company_description: '',
    company_website: '',
    selling_points: [],
    sell: '',
    audience: {
      focused_titles: [],
      geographies: [],
      company_size: [],
      departments: [],
      industries: [],
      tools: [],
      title_ids: [],
      titles: [],
      customers: [],
      excluding_companies: [],
      excluding_websites: [],
      custom_titles: [],
      custom_tools: [],
      custom_departments: [],
      custom_industries: [],
      custom_geographies: [],
      custom_customers: [],
      custom_excluding_companies: [],
      sales_approach: '',
      ideal_customers: [],
      custom_ideal_customers: []
    },
    delivery_method: {
      delivery_mode: { linkedin: true, email: true },
      profile_id: null,
      send_leads_to: "",
      calendly_link: ""
    },
    creative: {
      product_name: '',
      company_description: '',
      problem: '',
      impact: '',
      materials_links: [],
      different_about_product: ''
    },
    budget: {
      budget_type: 'daily',
      budget: 100
    },
    success: "",
    price_point: "",
    hear_about_us: "",
    cost_per_lead: "",
    sales_cycle_length: ""
  },
  updateAudienceSize: false,
  campaign_analytics_filter_date: {
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    key: "selection"
  },
  analytics_filter_campaign_ids: undefined,
  hustle_bot: {
    current_operators: [],
    current_connections: [],
    client_id: null,
    add_operator: {
      type: null,
      in_between: false,
      id: null,
      source: null,
      target: null,
      sourceType: null,
      label: null,
      position: null
    },
    alert: {
      show: false,
      message: "",
      is_error: false
    },
    loaded: false,
    campaign_name: "",
    campaign_id: null,
    audience_name: null,
    campaign_phases: [],
    campaign_phase: "Stopped",
    workflow_options: [{value: 'Draft', label: 'Draft', color: "red"}],
    workflow_view: { value: "Draft", label: "Draft"},
    message_touches: [],
    copy_view: {
      loaded: false,
      messages: [],
      message_id: null,
      workflow_variant_id: null,
      message_variant_id: null,
      message_variant_history_id: null
    }
  }
};

const updateState = (name, state, payload) => {
  if(name === "scraped_campaign_data") {
    return Object.assign({}, state, {
      isAiFormAnswers: payload.isAiFormAnswers,
      aiFormAnswersLoading: payload.aiFormAnswersLoading,
      campaignData: payload.campaignData,
      aiAnswersLoading: payload.aiAnswersLoading,
    })
  }

  if(name === "campaignMetaData") {
    return Object.assign({}, state, {
      campaignPhaseId: payload.campaignPhaseId,
      sampledProspects: payload.sampledProspects,
    })
  }

  return Object.assign({}, state, {
    [name]: payload
  })
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_CAMPAIGN_STATUS:
      return updateState("campaignStatus", state, action.payload);
    case actionTypes.SET_CAMPAIGN_ID:
      return updateState("campaign_id", state, action.payload);
    case actionTypes.SET_CAMPAIGN_DATA:
      return updateState('campaignData', state, action.payload);
    case actionTypes.SET_FORECASTED_REACH:
      return updateState('forecastedReach', state, action.payload);
    case actionTypes.SET_CAMPAIGN_LOADER:
      return updateState("campaignLoader", state, action.payload);
    case actionTypes.SET_TARGET_AUDIENCE:
      return updateState("targetAudience", state, action.payload);
    case actionTypes.SET_SAMPLED_PROSPECTS:
      return updateState("sampledProspects", state, action.payload);
    case actionTypes.SET_CREATIVE_ATTACHMENTS:
      return updateState("creative_attachments", state, action.payload);
    case actionTypes.SET_AUDIENCE_ATTACHMENTS:
      return updateState("audience_attachments", state, action.payload);
    case actionTypes.SET_INCOMPLETE_STATUS:
      return updateState("incompleteFields", state, action.payload);
    case actionTypes.UPDATE_AUDIENCE_SIZE:
      return updateState("updateAudienceSize", state, action.payload);
    case actionTypes.SET_CAMPAIGN_PHASE:
      return updateState("campaignPhaseId", state, action.payload);
    case actionTypes.SET_EDIT_MODE:
      return updateState("isEditModeEnabled", state, action.payload);
    case actionTypes.SET_DRAFT_CAMPAIGN_ID:
      return updateState("draft_campaign_id", state, action.payload);
    case actionTypes.SET_EDITED_STEPS:
      return updateState("editedSteps", state, action.payload);
    case actionTypes.SET_DRAFT_CAMPAIGN_BUDGET:
      return updateState("draft_campaign_budget", state, action.payload);
    case actionTypes.SET_DRAFT_CAMPAIGN_BUDGET_TYPE:
      return updateState("draft_campaign_budget_type", state, action.payload);
    case actionTypes.SET_DRAFT_CAMPAIGN_SUCCESS:
      return updateState("draft_campaign_success", state, action.payload);
    case actionTypes.SET_DRAFT_CAMPAIGN_PRICE_POINT:
      return updateState("draft_campaign_price_point", state, action.payload);
    case actionTypes.SET_DRAFT_CAMPAIGN_COST_PER_LEAD:
      return updateState("draft_campaign_cost_per_lead", state, action.payload);
    case actionTypes.SET_DRAFT_CAMPAIGN_SALES_CYCLE_LENGTH:
      return updateState("draft_campaign_sales_cycle_length", state, action.payload);
    case actionTypes.SET_IS_CAMPAIGN_MANAGER_GUEST:
      return updateState("isCampaignManagerGuest", state, action.payload);
    case actionTypes.SET_IS_NEW_USER:
      return updateState("isNewUser", state, action.payload);
    case actionTypes.SET_IS_CAMPAIGN_EDITABLE:
      return updateState("isCampaignEditable", state, action.payload);
    case actionTypes.SET_ATTACHMENT_CAMPAIGN_ID:
      return updateState("attachmentCampaignId", state, action.payload);
    case actionTypes.RESET_CAMPAIGN_DATA:
      return initialState;
    case actionTypes.SET_TOGGLE_EDIT_LOADER:
      return updateState("toggleEditLoader", state, action.payload);
    case actionTypes.SET_ANALYTICS_FILTER_DATE:
      return updateState("campaign_analytics_filter_date", state, action.payload);
    case actionTypes.SET_FILTER_CAMPAIGN_IDS:
      return updateState("analytics_filter_campaign_ids", state, action.payload);
    case actionTypes.SET_IS_CAMPAIGN_MANAGER_GUEST_LOADER:
      return updateState("isCampaignManagerGuestLoader", state, action.payload);
    case actionTypes.SET_AI_FORM_ANSWERS_LOADING:
      return updateState("aiFormAnswersLoading", state, action.payload);
    case actionTypes.SET_IS_AI_FORM_ANSWERS:
      return updateState("isAiFormAnswers", state, action.payload);
    case actionTypes.SET_AI_ANSWERS_LOADING:
      return updateState("aiAnswersLoading", state, action.payload);
    case actionTypes.SET_AI_ANSWERS_ERROR:
      return updateState("aiAnswersError", state, action.payload);
    case actionTypes.SET_HUSTLE_BOT_DATA:
      return updateState("hustle_bot", state, action.payload);
    case actionTypes.SET_SCRAPED_CAMPAIGN_DATA:
      return updateState("scraped_campaign_data", state, action.payload);
    case actionTypes.SET_IS_FORM_COMPLETED:
      return updateState("isFormCompleted", state, action.payload);
    case actionTypes.SET_HIDE_AI_BOT:
      return updateState("hideAiBot", state, action.payload);
    case actionTypes.SET_LOAD_SAMPLED_PROSPECT:
      return updateState("loadSampledProspect", state, action.payload);
    case actionTypes.SET_CAMPAIGN_META_DATA:
      return updateState("campaignMetaData", state, action.payload);
    default:
      return state;
  }
};