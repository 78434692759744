import * as actionTypes from "../../actions/Insights/action-types";

export const initialState = {
  companyLoader: false
}

const updateState = (name, state, payload) => {
  return Object.assign({}, state, {
    [name]: payload
  })
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_COMPANY_LOADER:
      return updateState("companyLoader", state, action.payload);
    default:
      return state;
  }
}