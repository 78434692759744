import * as apiCall from "../../axios";
let apiVersion = 'v3';

export const getCompanyDomains = (linkedin_url, website, companyName) => {
  let params = {
    linkedin_url: linkedin_url,
    website: website,
    company_name: companyName,
    per_page: 1000,
    page: 0,
    api_key: process.env.AUTH_TOKEN
  }

  return apiCall.sendPostRequest(`/api/${apiVersion}/company_domains`, params);
}


export const getCompanyDetails = (id) => {
  return apiCall.sendRequest(`/api/${apiVersion}/buying_committees`, { company_id: id, api_key: process.env.AUTH_TOKEN });
}

export const getPDLSearchData = (domains, size, linkedin_url, company_names, linkedin_urls, scroll_token, page_size, last_request, request_type) => {
  let params = {
    domains: domains,
    size: size,
    linkedin_url: linkedin_url,
    company_names: company_names,
    linkedin_urls: linkedin_urls,
    scroll_token: scroll_token,
    page_size: page_size,
    last_request: last_request,
    api_key: process.env.AUTH_TOKEN
  }

  return apiCall.sendPostRequest(`/api/${apiVersion}/${request_type}`, params);
}

export const getPDLSearchData1 = async (domains, size, linkedin_url, company_names, linkedin_urls, scroll_token, page_size, last_request, request_type) => {

  let params = {
    domains: domains,
    size: size,
    linkedin_url: linkedin_url,
    company_names: company_names,
    linkedin_urls: linkedin_urls,
    scroll_token: scroll_token,
    page_size: page_size,
    last_request: last_request,
    api_key: process.env.AUTH_TOKEN
  }

  return await apiCall.sendPostRequest(`/api/${apiVersion}/${request_type}`, params);
}

export const getStakeholderByPagination = async (companyName, companyWebsite, linkedinUrl, organizationLevel, page) => {

  let params = {
    company_name: companyName,
    website: companyWebsite,
    linkedin_url: linkedinUrl,
    organization_level: organizationLevel,
    api_key: process.env.AUTH_TOKEN
  }

  let res = await apiCall.sendPostRequest(`/api/${apiVersion}/decision_makers`, params)

  let records = res.data.records

  if (records && page >= 0) {
    if (records.length > 0 && (res.data.total_page - 1) > page) {
      let concatRecords = records.concat(await getStakeholderByPagination(companyName, companyWebsite, linkedinUrl, page + 1))
      return [concatRecords, res.data.authorize_credit_access, res.data.decision_makers_count]
    } else {
      if (page === 0) {
        return [records, res.data.authorize_credit_access, res.data.decision_makers_count]
      } else {
        return records
      }
    }
  } else {
    let { decision_makers_data } = res.data;
    return [decision_makers_data || []]
  }

}

export const getSelectedStakeHoldersData = async (recordData) => {

  let params = {
    decision_makers_params: recordData.selectedRecords,
    domains: recordData.domains,
    company_names: recordData.company_names,
    linkedin_urls: recordData.linkedin_urls,
    last_request: recordData.isOverAllLastRequest,
    sent_data_count: recordData.responseCount,
    company_url: recordData.linkedin_url,
    organization_level: recordData.organization_level,
    api_key: process.env.AUTH_TOKEN
  }

  let res = await apiCall.sendPostRequest(`/api/${apiVersion}/decision_makers_data`, params);

  return res.data ? ([res.data.record, res.data.organization_level] || []) : []
}