import * as apiCall from '../../../axios'

let apiVersion = 'v3';

export const getAccountSettingsData = () => {
  return apiCall.sendRequest(`/api/${apiVersion}/account_settings_data`)
}

export const updateUserData = (data) => {
  return apiCall.sendPostRequest(`/account/settings/update_user`, {user: data });
}