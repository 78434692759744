import * as apiCall from '../../axios';
import * as actionTypes from './action-types';

let apiVersion = 'v3';

// Company data
export const getCompanies = () => {
  return apiCall.sendRequest(`/api/${apiVersion}/insights_companies`, {});
};

export const setCompanyLoader = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_COMPANY_LOADER, payload: data });
}