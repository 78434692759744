import combineReducers from 'react-combine-reducers';
import { initialState as accountState, reducer as accountReducer } from "./Account";
import { initialState as insightsState, reducer as insightsReducer } from "./Insights";
import { initialState as homeState, reducer as homeReducer } from "./Home";


export const [reducer, initialState] = combineReducers({
  account: [accountReducer, accountState],
  insights: [insightsReducer, insightsState],
  home: [homeReducer, homeState]
});

